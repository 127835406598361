<template>
	<div class="home">
		<div v-if="!pageLoadErr">
			<div class="ad" id="banner_2_0"></div>
			<van-nav-bar class="nav-bar" title="输入金额进行支付" />
			<div>
				<!-- <button @click="test()">。。。</button> -->
				<div class="pay-title">付款给：{{ qrcodeInfo.name }}</div>
				<div class="form-container">
					<van-field class="input-container" @click="showKey" v-model="amount" label="金额" readonly
						placeholder="请输入金额" />
					<!-- <van-field class="input-container" v-model="remark" label="备注"
            :placeholder="'请输入备注' + (qrcodeInfo.isRemarkRequired == '1' ? '(必填)' : '')" /> -->
					<!-- <div class="label">{{qrcodeInfo.remark1}}</div> -->
					<van-divider content-position="left" :style="{ color: '#1989fa', borderColor: '#1989fa' }"
						v-if="qrcodeInfo.remark1!=null&&qrcodeInfo.remark1!=''">
						{{ qrcodeInfo.remark1 }}
					</van-divider>
					<van-field class="input-container" v-if="qrcodeInfo.remark1!=null&&qrcodeInfo.remark1!=''"
						v-model="remark1"
						:placeholder="'请输入'+qrcodeInfo.remark1+(qrcodeInfo.isRemark1Required=='1'?'(必填)':'(非必填)')" />

					<van-divider content-position="left" :style="{ color: '#1989fa', borderColor: '#1989fa' }"
						v-if="qrcodeInfo.remark2!=null&&qrcodeInfo.remark2!=''">
						{{ qrcodeInfo.remark2 }}
					</van-divider>
					<van-field class="input-container" v-if="qrcodeInfo.remark2!=null&&qrcodeInfo.remark2!=''"
						v-model="remark2"
						:placeholder="'请输入'+qrcodeInfo.remark2+(qrcodeInfo.isRemark2Required=='1'?'(必填)':'(非必填)')" />

					<van-divider content-position="left" :style="{ color: '#1989fa', borderColor: '#1989fa' }"
						v-if="qrcodeInfo.remark3!=null&&qrcodeInfo.remark3!=''">
						{{ qrcodeInfo.remark3 }}
					</van-divider>
					<van-field class="input-container" v-if="qrcodeInfo.remark3!=null&&qrcodeInfo.remark3!=''"
						v-model="remark3"
						:placeholder="'请输入'+qrcodeInfo.remark3+(qrcodeInfo.isRemark3Required=='1'?'(必填)':'(非必填)')" />

					<van-divider content-position="left" :style="{ color: '#1989fa', borderColor: '#1989fa' }"
						v-if="qrcodeInfo.remark4!=null&&qrcodeInfo.remark4!=''">
						{{ qrcodeInfo.remark4 }}
					</van-divider>
					<van-field class="input-container" v-if="qrcodeInfo.remark4!=null&&qrcodeInfo.remark4!=''"
						v-model="remark4"
						:placeholder="'请输入'+qrcodeInfo.remark4+(qrcodeInfo.isRemark4Required=='1'?'(必填)':'(非必填)')" />

					<van-divider content-position="left" :style="{ color: '#1989fa', borderColor: '#1989fa' }"
						v-if="qrcodeInfo.remark5!=null&&qrcodeInfo.remark5!=''">
						{{ qrcodeInfo.remark5 }}
					</van-divider>
					<van-field class="input-container" v-if="qrcodeInfo.remark5!=null&&qrcodeInfo.remark5!=''"
						v-model="remark5"
						:placeholder="'请输入'+qrcodeInfo.remark5+(qrcodeInfo.isRemark5Required=='1'?'(必填)':'(非必填)')" />

				</div>
				<div class="pay-button-container" v-if="openId.length == 0">
					<!-- <van-button color="#56588e" @click="toPay()" round type="info" block>前往支付</van-button> -->
					<button id="btn" :data-clipboard-text="qrcodeInfo.adtext" @click="toPay()">前往支付</button>
					<div style="height: 20px;"></div>
					<button id="Alipaybtn" :data-clipboard-text="qrcodeInfo.adtext" @click="showPopup"
						v-if="channelCode()=='支付宝支付'?false:true">前往支付宝支付</button>
					<van-popup v-model="Alipayshow" position="top" round>如果需要前往支付宝，请先点击右上角
						<van-icon name="weapp-nav" />，【在浏览器中打开】。
					</van-popup>
					<div style="height: 20px;"></div>
					<button id="unionBtn" :data-clipboard-text="qrcodeInfo.adtext" @click="toUnion()"
						v-if="[ 'fuyou','weifutong','huiwangcai','yibao', 'lakala'].includes(qrcodeInfo.platform)">使用云闪付支付</button>
					<!--					<button id="unionBtn" :data-clipboard-text="qrcodeInfo.adtext" @click="toUnion()"-->
					<!--						v-if="[ 'yibao', 'lakala'].includes(qrcodeInfo.platform)">使用云闪付支付</button>-->
				</div>

				<van-dialog v-model="dialogShow" :show-cancel-button="true" :showConfirmButton="false"
					cancelButtonColor="red" title="请手动截图保存后,前往云闪付APP进行支付">
					<vue-qr :text="payUrl" :size="300" :logoSrc="logo" :logoScale="0.2">
					</vue-qr>
				</van-dialog>

				<van-number-keyboard :show="keyShow" title="请输入" extra-key="." close-button-text="完成"
					@blur="keyShow = false" @input="onKeyInput" @delete="onKeyDelete" @close="onKeyClose">
				</van-number-keyboard>
			</div>
		</div>
		<div v-if="pageLoadErr && errMsg">
			<van-empty :description="errMsg" />
		</div>
		<van-overlay :show="loadingShow">
			<div class="wrapper">
				<van-loading />
			</div>
		</van-overlay>
	</div>

</template>

<script>
	import Vue from 'vue'
	import axios from 'axios'
	import Clipboard from 'clipboard';
	import VueQr from 'vue-qr'
	// import foot from "@/components/foot";
	// import TencentGDT from '//qzonestyle.gtimg.cn/qzone/qzact/act/qq-ad-sdk/qqad.js'
	import {
		NavBar,
		Divider,
		Field,
		NumberKeyboard,
		Cell,
		Button,
		Dialog,
		Empty,
		Overlay,
		Loading,
		Popup,
		Swipe,
		SwipeItem,
		Lazyload,
		Image as VanImage,
		Badge
	} from 'vant'

	Vue.use(NavBar).use(Divider).use(Field).use(NumberKeyboard).use(Cell).use(Button).use(Dialog).use(Empty).use(Overlay)
		.use(Loading).use(Popup).use(Swipe).use(SwipeItem).use(Lazyload).use(VanImage).use(Badge)

	export default {
		name: 'Home',
		components: {
			// foot,
			VueQr
		},
		data() {
			return {
				textToCopy: "要复制的文本",
				totalClicks: 0,
				qrcodeId: null,
				openId: '',
				amount: '',
				remark: null,
				keyShow: false,
				pageLoadErr: true,
				loadingShow: false,
				errMsg: null,
				remark1: null,
				remark2: null,
				remark3: null,
				remark4: null,
				remark5: null,
				qrcodeInfo: {},
				show: false,
				weixin_url: "",
				isShow: null,
				dialogShow: null,
				payUrl: null,
				logo: require('@/assets/union.jpg'),
				Alipayshow: false,
			}
		},

		props: {},
		methods: {


			copy() {
				console.log("进入方法")
				var clipboard = new Clipboard('#btn');
				clipboard.on('success', function(e) {
					console.info('Action:', e.action);
					console.info('Text:', e.text);
					// console.info('Trigger:', e.trigger);
					// e.clearSelection();
				});

				clipboard.on('error', function(e) {
					console.error('Action:', e.action);
					// console.error('Trigger:', e.trigger);
				});

			},
			// getPays() { //记录支付点击率
			// 	console.log("this.qrcodeInfo:" + this.qrcodeInfo.name);
			// 	for (var prop in this.qrcodeInfo) {
			// 		console.log(prop + ': ' + this.qrcodeInfo[prop]);
			// 	}
			// 	const params = {
			// 		name: this.qrcodeInfo.name,
			// 		unitId: this.qrcodeInfo.unitId,
			// 		id: this.qrcodeInfo.id,
			// 	}
			// 	axios.post('/pay/click', params)
			// 		.then(function(response) {
			// 			console.log(response);
			// 		})
			// 		.catch(function(error) {
			// 			console.log(error);
			// 		});
			// },
			channelCode() {
				var ua = window.navigator.userAgent.toLowerCase()
				console.log(ua)
				if (ua.match(/MicroMessenger/i) == 'micromessenger') {

					return '微信支付'
				} else if (ua.match(/AlipayClient/i) == 'alipayclient') {
					return '支付宝支付'
				} else {
					return '请用微信、支付宝扫码'
				}
			},
			showPopup() {
				this.Alipayshow = true
			},
			/**
			 * 加载支付二维码的信息
			 */
			loadQrcodeInfo() {
				this.loadingShow = true
				axios.get(window.RequestHost + '/pay/qrcode/' + this.qrcodeId).then(res => {
					// axios.get("http://localhost:8888" + '/pay/qrcode/' + this.qrcodeId).then(res => {
					if (res.data.success) {
						if (res.data.data.isEnabled == '1') {
							this.pageLoadErr = false
							this.qrcodeInfo = res.data.data
							//自动跳转支付宝
							var ua = window.navigator.userAgent.toLowerCase()
							console.log('测试' + ua)
							// alert(ua)
							if ((ua.match(/MicroMessenger/i) != 'micromessenger') && (ua.match(
										/AlipayClient/i) !=
									'alipayclient')) {
								var payId = res.data.data.id
								console.log('测试' + payId)
								window.location.href =
									'alipays://platformapi/startapp?appId=20000067&url=http://m.qrpay.jztda.com.cn/' +
									'%23' + '/' + payId
							}
							//

						} else {
							this.pageLoadErr = true
							this.errMsg = '当前支付码暂不可用'
						}
					} else {
						this.pageLoadErr = true
						this.errMsg = res.data.msg
					}
					this.loadingShow = false
				}, err => {
					console.log(err)
					this.loadingShow = false
					this.pageLoadErr = true
					this.errMsg = '发生了一个网络错误'
				})
			},
			/**
			 * 数字键盘输入时触发
			 * @param value
			 */
			onKeyInput(value) {
				if (this.amount.length == 0 && value == '.') {
					return
				}
				if (this.amount.indexOf('.') != -1 && value == '.') {
					return
				}
				if (this.amount.indexOf('.') != -1 && this.amount.indexOf('.') <= this.amount.length - 3) {
					return
				}
				if (this.amount == '0' && value != '.') {
					return
				}
				this.amount += value
			},
			/**
			 * 数字键盘删除时触发
			 */
			onKeyDelete() {
				if (this.amount.length == 0) {
					return
				}
				this.amount = this.amount.substring(0, this.amount.length - 1)
			},
			/**
			 * 显示数字键盘
			 */
			showKey() {
				this.keyShow = true
			},
			/**
			 * 关闭数字键盘
			 */
			onKeyClose() {
				this.keyShow = false
				if (this.amount.indexOf('.') == this.amount.length - 1) {
					this.onKeyDelete()
				}
				if (this.amount == '0') {
					this.amount = null
				}
			},
			/**
			 * 前往云闪付
			 */
			toUnion() {
				if (this.qrcodeInfo.adopen == 'yes') {
					this.$options.methods.copy();
				}

				this.show = true;
				if (this.amount == null || this.amount.length == 0 || this.amount == '0') {
					Dialog.alert({
						message: '请输入金额',
						theme: 'round-button'
					})
					return
				}
				if (this.amount.length > 9) {
					Dialog.alert({
						message: '金额过大',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark1Required == '1' && (this.remark1 == null || this.remark1.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark1 != null && this.remark1.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark2Required == '1' && (this.remark2 == null || this.remark2.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark2 != null && this.remark2.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark3Required == '1' && (this.remark3 == null || this.remark3.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark3 != null && this.remark3.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark4Required == '1' && (this.remark4 == null || this.remark4.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark4 != null && this.remark4.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark5Required == '1' && (this.remark5 == null || this.remark5.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark5 != null && this.remark5.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				this.dialogShow = true
				// this.$options.methods.getPays(); //记录支付次数
				// const par = {
				// 	name: this.qrcodeInfo.name,
				// 	unitId: this.qrcodeInfo.unitId,
				// 	id: this.qrcodeInfo.id,
				// }
				// axios.post('/pay/click', par) //记录支付次数
				// 	.then(function(response) {
				// 		console.log(response);
				// 	})
				// 	.catch(function(error) {
				// 		console.log(error);
				// 	});


				this.loadingShow = true
				const params = {
					total: this.amount,
					note: this.remark1,
					remark2: this.remark2,
					remark3: this.remark3,
					remark4: this.remark4,
					remark5: this.remark5,
					qrcodeId: this.qrcodeId,
					browserUserAgent: navigator.userAgent,
					wxMpOpenId: this.openId,
					union: "union"
				}
				// alert(JSON.stringify(params))
				axios.post(window.RequestHost + '/pay/placeorder', params).then(res => {
					// axios.post("http://localhost:8888" + '/pay/placeorder', params).then(res => {
					this.loadingShow = false
					console.log(res)
					if (res.data.success) {
						const result = res.data.data
						console.log(result)
						// 业务调用不成功
						// if (!result.businessSuccess) {
						//   Dialog.alert({message: result.businessError, theme: 'round-button'})
						//   return
						// }
						// 如果后端有返回微信网页收取登录链接，直接跳转
						if (result.wxAuthUrl != undefined) {
							window.location.href = result.wxAuthUrl
							return
						}
						// 入如果商户属于威富通或汇旺财渠道，直接跳转二维码链接
						if (result.platform === 'weifutong' || result.platform === 'huiwangcai') {
							if (result.result_code == "0") {
								this.payUrl = result.code_url
							} else {
								Dialog.alert({
									message: '抱歉，该商户不支持云闪付支付',
									theme: 'round-button',
								})
							}
							return
						}
						if (result.platform === 'fuyou') {
							console.log('跳转富友')
							this.payUrl = result.qr_code
							return
						}
						if (result.platform === 'yibao') {
							console.log('跳转易宝')
							this.payUrl = result.qrCodeUrl
							return
						}
						if (result.platform === 'cib') {
							console.log('跳转兴业')
							console.log(result.code_url)
							window.location.href = result.code_url
							return
						}
						if (result.platform === 'lakala') {
							console.log('跳转拉卡拉')
							const payInfo = JSON.parse(result.resp_data);
							this.payUrl = payInfo.counter_url
						}
						if (result.platform === 'weixin') {
							console.log('跳转微信')
							// const payInfo = JSON.parse(result)
							// window.location.href = weixin_url
							if (result.browser === 'alipay') {
								var newland = result.newland
								if (newland.includes('qrpay.jztda')) {
									window.location.href = newland
									window.location.reload();
								} else {
									window.location.href = newland
								}
								// window.open(result.newland)
								// window.location.reload();
							} else {

								let that = this
								// eslint-disable-next-line no-undef
								if (WeixinJSBridge === undefined) {
									that.pageLoadErr = true
									that.errMsg = '请在微信中打开'
									return
								}
								window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
										'appId': result.app_id,
										'timeStamp': result.timestamp,
										'nonceStr': result.nonce,
										'package': result.prepay_id,
										'signType': "RSA",
										'paySign': result.paySign
									},
									function(res) {
										if (res.err_msg == 'get_brand_wcpay_request:ok') {
											// 使用以上方式判断前端返回,微信团队郑重提示：
											//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
											that.pageLoadErr = true
											that.errMsg = '支付成功'
										}
									})
							}


							this.show = true;
							return
						}
						// 如果商户属于汇付渠道
						if (result.platform === 'huifu') {
							// 如果是支付宝终端
							if (result.browser === 'alipay') {
								window.top.location = result.expend.pay_info
							} else if (result.browser === 'wechat') { // 如果是微信终端
								const payInfo = JSON.parse(result.expend.pay_info)
								let that = this
								// eslint-disable-next-line no-undef
								if (WeixinJSBridge === undefined) {
									that.pageLoadErr = true
									that.errMsg = '请在微信中打开'
									return
								}
								// eslint-disable-next-line no-undef
								WeixinJSBridge.invoke('getBrandWCPayRequest', {
										'appId': payInfo.appId,
										'timeStamp': payInfo.timeStamp,
										'nonceStr': payInfo.nonceStr,
										'package': payInfo.package,
										'signType': payInfo.signType,
										'paySign': payInfo.paySign
									},
									function(res) {
										if (res.err_msg == 'get_brand_wcpay_request:ok') {
											// 使用以上方式判断前端返回,微信团队郑重提示：
											//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
											that.pageLoadErr = true
											that.errMsg = '支付成功'
										}
									})

							}
						}

					} else {
						Dialog.alert({
							message: res.data.msg,
							theme: 'round-button'
						})
					}
				}, err => {
					this.loadingShow = false
					console.log(err)
					Dialog.alert({
						message: '发生了一个网络错误',
						theme: 'round-button'
					})

				})
			},
			/**
			 * 前往支付
			 */
			toPay() {
				if (this.qrcodeInfo.adopen == 'yes') {
					this.$options.methods.copy();
				}

				this.show = true;
				// console.log("1111111111111111this.qrcodeInfo.name:" + this.qrcodeInfo.name)
				if (this.amount == null || this.amount.length == 0 || this.amount == '0') {
					Dialog.alert({
						message: '请输入金额',
						theme: 'round-button'
					})
					return
				}
				if (this.amount.length > 9) {
					Dialog.alert({
						message: '金额过大',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark1Required == '1' && (this.remark1 == null || this.remark1.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark1 != null && this.remark1.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark2Required == '1' && (this.remark2 == null || this.remark2.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark2 != null && this.remark2.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark3Required == '1' && (this.remark3 == null || this.remark3.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark3 != null && this.remark3.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark4Required == '1' && (this.remark4 == null || this.remark4.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark4 != null && this.remark4.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				if (this.qrcodeInfo.isRemark5Required == '1' && (this.remark5 == null || this.remark5.trim().length ==
						0)) {
					Dialog.alert({
						message: '请输入完整所有必填项',
						theme: 'round-button'
					})
					return
				}
				if (this.remark5 != null && this.remark5.length > 127) {
					Dialog.alert({
						message: '备注不能超过127个字符',
						theme: 'round-button'
					})
					return
				}
				// this.$options.methods.getPays(); //记录支付次数
				// const par = {
				// 	name: this.qrcodeInfo.name,
				// 	unitId: this.qrcodeInfo.unitId,
				// 	id: this.qrcodeInfo.id,
				// }
				// axios.post('/pay/click', par) //记录支付次数
				// 	.then(function(response) {
				// 		console.log(response);
				// 	})
				// 	.catch(function(error) {
				// 		console.log(error);
				// 	});


				this.loadingShow = true
				const params = {
					total: this.amount,
					note: this.remark1,
					remark2: this.remark2,
					remark3: this.remark3,
					remark4: this.remark4,
					remark5: this.remark5,
					qrcodeId: this.qrcodeId,
					browserUserAgent: navigator.userAgent,
					wxMpOpenId: this.openId,
					union: "none"
				}
				// alert(JSON.stringify(params))
				axios.post(window.RequestHost + '/pay/placeorder', params).then(res => {
					// axios.post("http://localhost:8888" + '/pay/placeorder', params).then(res => {
					this.loadingShow = false
					console.log(res)
					if (res.data.success) {
						const result = res.data.data
						console.log(result)
						// 业务调用不成功
						// if (!result.businessSuccess) {
						//   Dialog.alert({message: result.businessError, theme: 'round-button'})
						//   return
						// }
						// 如果后端有返回微信网页收取登录链接，直接跳转
						if (result.wxAuthUrl != undefined) {
							window.location.href = result.wxAuthUrl
							return
						}
						// 入如果商户属于威富通或汇旺财渠道，直接跳转二维码链接
						if (result.platform === 'weifutong' || result.platform === 'huiwangcai') {
							window.location.href = result.code_url
							return
						}
						if (result.platform === 'fuyou') {
							console.log('跳转富友')
							window.location.href = result.qr_code
							return
						}
						if (result.platform === 'yibao') {
							console.log('跳转易宝')
							window.location.href = result.qrCodeUrl
							return
						}
						if (result.platform === 'cib') {
							console.log('跳转兴业')
							console.log(result.code_url)
							window.location.href = result.code_url
							return
						}
						if (result.platform === 'lakala') {
							console.log('跳转拉卡拉')
							const payInfo = JSON.parse(result.resp_data);
							// let that = this
							// alert(JSON.stringify(payInfo));
							window.location.href = payInfo.counter_url


							// console.log('跳转拉卡拉')
							// if (result.browser === 'alipay') {
							// 	const payInfo = JSON.parse(result.acc_resp_fields);
							// 	// let that = this
							// 	// alert(JSON.stringify(payInfo));
							// 	window.location.href = payInfo.code
							// } else if (result.browser === 'wechat') {
							// 	const payInfo = JSON.parse(result.acc_resp_fields);
							// 	let that = this
							// 	// alert(JSON.stringify(payInfo));
							// 	// eslint-disable-next-line no-undef
							// 	if (WeixinJSBridge === undefined) {
							// 		that.pageLoadErr = true
							// 		that.errMsg = '请在微信中打开'
							// 		return
							// 	}
							// 	window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
							// 			'appId': payInfo.app_id,
							// 			'timeStamp': payInfo.time_stamp,
							// 			'nonceStr': payInfo.nonce_str,
							// 			'package': payInfo.package,
							// 			'signType': "RSA",
							// 			'paySign': payInfo.pay_sign
							// 		},
							// 		function(res) {
							// 			if (res.err_msg == 'get_brand_wcpay_request:ok') {
							// 				// 使用以上方式判断前端返回,微信团队郑重提示：
							// 				//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
							// 				that.pageLoadErr = true
							// 				that.errMsg = '支付成功'
							// 			}
							// 		})
							// }
						}
						if (result.platform === 'weixin') {
							console.log('跳转微信')
							// const payInfo = JSON.parse(result)
							// window.location.href = weixin_url
							if (result.browser === 'alipay') {
								var newland = result.newland
								if (newland.includes('qrpay.jztda')) {
									window.location.href = newland
									window.location.reload();
								} else {
									window.location.href = newland
								}
								// window.open(result.newland)
								// window.location.reload();
							} else {

								let that = this
								// eslint-disable-next-line no-undef
								if (WeixinJSBridge === undefined) {
									that.pageLoadErr = true
									that.errMsg = '请在微信中打开'
									return
								}
								window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
										'appId': result.app_id,
										'timeStamp': result.timestamp,
										'nonceStr': result.nonce,
										'package': result.prepay_id,
										'signType': "RSA",
										'paySign': result.paySign
									},
									function(res) {
										if (res.err_msg == 'get_brand_wcpay_request:ok') {
											// 使用以上方式判断前端返回,微信团队郑重提示：
											//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
											that.pageLoadErr = true
											that.errMsg = '支付成功'
										}
									})
							}


							this.show = true;
							return
						}
						// 如果商户属于汇付渠道
						if (result.platform === 'huifu') {
							// 如果是支付宝终端
							if (result.browser === 'alipay') {
								window.top.location = result.expend.pay_info
							} else if (result.browser === 'wechat') { // 如果是微信终端
								const payInfo = JSON.parse(result.expend.pay_info)
								let that = this
								// eslint-disable-next-line no-undef
								if (WeixinJSBridge === undefined) {
									that.pageLoadErr = true
									that.errMsg = '请在微信中打开'
									return
								}
								// eslint-disable-next-line no-undef
								WeixinJSBridge.invoke('getBrandWCPayRequest', {
										'appId': payInfo.appId,
										'timeStamp': payInfo.timeStamp,
										'nonceStr': payInfo.nonceStr,
										'package': payInfo.package,
										'signType': payInfo.signType,
										'paySign': payInfo.paySign
									},
									function(res) {
										if (res.err_msg == 'get_brand_wcpay_request:ok') {
											// 使用以上方式判断前端返回,微信团队郑重提示：
											//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
											that.pageLoadErr = true
											that.errMsg = '支付成功'
										}
									})

							}
						}

					} else {
						Dialog.alert({
							message: res.data.msg,
							theme: 'round-button'
						})
					}
				}, err => {
					this.loadingShow = false
					console.log(err)
					Dialog.alert({
						message: '发生了一个网络错误',
						theme: 'round-button'
					})

				})

			},
			getads() { //记录广告成功率
				console.log("this.qrcodeInfo:" + this.qrcodeInfo.name);
				for (var prop in this.qrcodeInfo) {
					console.log(prop + ': ' + this.qrcodeInfo[prop]);
				}
				// const params = {
				// 	name: this.qrcodeInfo.name,
				// 	unitId: this.qrcodeInfo.unitId,
				// 	id: this.qrcodeInfo.id,
				// }
				// axios.post('/pay/ad', params)
				// 	.then(function(response) {
				// 		console.log(response);
				// 	})
				// 	.catch(function(error) {
				// 		console.log(error);
				// 	});
			},
			test() {
				let that = this
				// eslint-disable-next-line no-undef
				if (WeixinJSBridge === undefined) {
					that.pageLoadErr = true
					that.errMsg = '请在微信中打开'
					return
				}
				// eslint-disable-next-line no-undef
				WeixinJSBridge.invoke('getBrandWCPayRequest', {
						'appId': "wxd23604aba7ed0487",
						'timeStamp': "1706585605",
						'nonceStr': "5d8c91729c7949aca52a5c2884855895",
						'package': "prepay_id=wx30113325784057378d84473affadcb0000",
						'signType': "RSA",
						'paySign': "UqG+9+a9yzCR4T/uHA4LOUW/BOP65ueRMehCgwATOT3oO5PQtJ6PBcPke/TtorwmjXY0HukbU6+ZPjw3srFT/TIlWzbeSkA/11k1DoPvmAwRKZ2OKiKB+VZgmM9qP0CruQWwolDcZ1GtYFcikFYR5U2yMDzNQeiFMqmJ8xgFoX9btwY9WgQ742+cV2bcHrgrdLBO12z1nt+oIGZRgw81E9Z1YXBQTtoFDyrnI/oe8ess3roB0FL07hZxett5UhOjdEZDxcUi5PX0LrdwDsHYhw7aIIN43+6KxxPfoDP1orxa5JzxZsgrB7udVhZK9DUafWDEnI/qSpKNfzMuFJARkA=="
					},
					function(res) {
						if (res.err_msg == 'get_brand_wcpay_request:ok') {
							// 使用以上方式判断前端返回,微信团队郑重提示：
							//res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
							that.pageLoadErr = true
							that.errMsg = '支付成功'
						}
					})
			},



		},
		mounted() {
			if (!sessionStorage.getItem('isRefreshed')) {
				sessionStorage.setItem('isRefreshed', true);
				location.reload();
			}
			window.onload = () => {
				// 等待页面加载完成后执行的代码
				const vm = this;
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.async = true;
				script.src = '//qzs.qq.com/qzone/biz/res/i.js';
				document.getElementsByTagName('head')[0].appendChild(script);

				window.TencentGDT = window.TencentGDT || [];
				window.TencentGDT.push({
					app_id: '1202393694',
					placement_id: '5075345498963035',
					type: 'native',
					display_type: 'banner',
					carousel: 3000,
					containerid: 'banner_2_0',
					onComplete: function(res) {
						console.log(res)
						if (res.ret == 0) {
							console.log('广告播放成功');
							vm.getads();
						} else {
							console.log('广告播放失败');
						}
					}
				}); //腾讯广告
			};


		},

		created() {
			this.amount = this.$route.query.total ? this.$route.query.total : ''
			this.remark = this.$route.query.note ? this.$route.query.note : ''
			this.openId = this.$route.query.openId ? this.$route.query.openId : ''
			this.errMsg = this.$route.query.error ? this.$route.query.error : ''
			if (this.errMsg.length > 0) {
				this.pageLoadErr = true
				return
			}
			if (!this.$route.params.id) {
				this.errMsg = '无效的支付码'
			} else {
				this.qrcodeId = this.$route.params.id
				if (this.openId.length > 0) {
					this.toPay()
				} else {
					this.loadQrcodeInfo()
				}

			}
		}
	}
</script>



<style scoped>
	body {
		min-height: 100vh;
		position: relative;
		margin: 0px;
		padding: 0px;
	}

	.label {
		border: 1px solid red;
	}

	.nav-bar {
		background: #ffffff;
		border-bottom: 4px solid #56588e;
	}

	.form-container {
		padding: 12px 12px 12px 12px;
	}

	.pay-title {
		border-radius: 10px;
		padding: 12px;
		margin: 12px;
		background: #ffffff;
		color: #bd2a2a;
		font-size: 15px;
	}

	.input-container {
		border-radius: 30px;
		margin-bottom: 12px;
	}

	.pay-button-container {
		padding: 12px;
	}

	.home {
		background: #f2f2f2;
		height: 100vh;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	#btn {
		width: 100%;
		/*设置按钮宽度*/
		height: 40px;
		/*设置按钮高度*/
		color: white;
		/*字体颜色*/
		background-color: #56588e;
		/*按钮背景颜色*/
		border-radius: 20px;
		/*让按钮变得圆滑一点*/
		border-width: 0;
		/*消去按钮丑的边框*/
		margin: 0;
		outline: none;
		/*取消轮廓*/
		font-size: 14px;
		/*设置字体大小*/
		text-align: center;
		/*字体居中*/
	}

	#Alipaybtn {
		width: 100%;
		/*设置按钮宽度*/
		height: 40px;
		/*设置按钮高度*/
		color: white;
		/*字体颜色*/
		background-color: #00aaff;
		/*按钮背景颜色*/
		border-radius: 20px;
		/*让按钮变得圆滑一点*/
		border-width: 0;
		/*消去按钮丑的边框*/
		margin: 0;
		outline: none;
		/*取消轮廓*/
		font-size: 14px;
		/*设置字体大小*/
		text-align: center;
		/*字体居中*/
	}

	#unionBtn {
		width: 100%;
		/*设置按钮宽度*/
		height: 40px;
		/*设置按钮高度*/
		color: white;
		/*字体颜色*/
		background-color: #f44336;
		/*按钮背景颜色*/
		border-radius: 20px;
		/*让按钮变得圆滑一点*/
		border-width: 0;
		/*消去按钮丑的边框*/
		margin: 0;
		outline: none;
		/*取消轮廓*/
		font-size: 14px;
		/*设置字体大小*/
		text-align: center;
		/*字体居中*/
	}

	.ad {
		position: absolute;
		bottom: 0;
		width: 100%;
	}

	.footer .count p {
		/* padding: 12px; */
		font-size: 1px;
		text-align: right;
		color: gray;
	}

	.footer {
		/* position: absolute; */
		display: block;
		bottom: 0;
		width: 100%;
		text-align: center;
	}

	.footer p {
		color: grey;
		font-size: 15px;
	}

	.footer a {
		color: #42b983;
		text-decoration: underline;
	}
</style>